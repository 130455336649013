import axios from 'axios';

const axiosInstance = axios.create({
    //baseURL: 'http://localhost:8383/', // Set your default base URL here
    baseURL: 'https://risco.valori.com.vc:8483/', // Set your default base URL here
    timeout: 300000, // Set a default timeout
    headers: {
        'Content-Type': 'application/json',
        // You can set other default headers here if needed
    },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Get token from localStorage
        const token = localStorage.getItem('token');
        // If token exists, add it to the request headers
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // If the response status is 403, redirect the user to the login page
        if (error.response && error.response.status === 403) {
            // Redirect logic here, for example:
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('nomeLoja');
            localStorage.removeItem('lojaSelecionada');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);


export default axiosInstance;
